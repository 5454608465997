import { combineReducers } from 'redux';
import customerReducer from './customer';
import productReducer from './product';
import userReducer from './user';
import shoppingCartReducer from './shoppingCart';
import total from './total';
import orders from './order';
import activeOrder from './activeOrder';
import pastOrder from './pastOrder';


export default combineReducers({
  customers: customerReducer,
  products: productReducer,
  user: userReducer,
  shoppingCart: shoppingCartReducer,
  total: total,
  orders: orders,
  activeOrder: activeOrder,
  pastOrder: pastOrder
});