import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getProducts } from '../../store/actions/product';
import './AdminProducts.css';
import AddProduct from '../AddProduct/AddProduct.js';



class AdminProducts extends Component {

  constructor() {
    super();
    this.state = {
      products: []
    };
  }

  setProductsToState(products) {
    const productsState = products.map(product => {
      delete product.created_at
      delete product.updated_at
      return product
    })
    this.setState({
      products: productsState
    })
  }

  async componentWillMount() {
    const currentProducts = await this.props.getProducts();
    this.setProductsToState(currentProducts.payload);
  }

  render() {
    const products = this.state.products.map(product=>{
      return (
        <tr>
          <td>
            {product.id}
          </td>
          <td>
            {product.name}
          </td>
          <td>
            {product.shortDescription}
          </td>
          <td>
            {product.fullDescription}
          </td>
          <td>
            {product.price}
          </td>
        </tr>
      )
    })
    return (
      <div>
        <table className='admin-products-table'>
          <tbody>
            <tr>
              <td>
                Product ID
              </td>
              <td>
                Product Name
              </td>
              <td>
                Product shortDescription
              </td>
              <td>
                Product fullDescription
              </td>
              <td>
                Product price
              </td>
            </tr>
            {products}
          </tbody>
        </table>
        <AddProduct />
      </div>
    )
  }
};


const mapStateToProps = (state) => ({
  products: state.products
})

const dispatchToProps = (dispatch) => ({
  getProducts: () => dispatch(getProducts())
})

export default connect(mapStateToProps, dispatchToProps)(AdminProducts);
