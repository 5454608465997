import React from 'react';
import './SuperCritical.css';

const SuperCritical = () => {
  return (
    <div className='SuperCritical'>
      <div className='superCritical-text-container'>
        <div className='superCritical-darker'>

          <h3 className='superCritical-text superCritical-title'>WHY DO WE USE <span className='superCritical-bold'>SUBCRITICAL & SUPERCRITICAL</span> CO2 EXTRACTION?</h3>
          <h4 className='superCritical-text superCritical-subtitle'>A SAFER, CLEANER, PURER & FASTER WAY TO EXTRACT BOTANICAL OILS</h4>
          <p className='superCritical-text superCritical-p'>
            <span className='superCritical-bold'>Non-Toxic: </span>
            With CO2 as a solvent for oil extraction, no toxins, heavy metals or hydrocarbon materials come in contact with the extracted oils. Generally Regarded as Safe (GRAS) by the FDA for use in food products and commonly found in carbonated beverages. Our bodies produce it when we breathe!
          </p>
          <p className='superCritical-text superCritical-p'>
            <span className='superCritical-bold'>Natural: </span>
            Other extraction solvents, such as hydrocarbon-based propellants like propane and butane, hexane and pentane, or ethanol/alcohol mixtures, require additional distillation or purging beyond the extraction process to separate the solvent from the extracted oil. CO2 has a very low boiling temperature and wants to be a gas at room temperature, so it naturally separates from the extracted oil, the same way a soda goes “flat.”
          </p>
          <p className='superCritical-text superCritical-p'>
            <span className='superCritical-bold'>Environmentally Friendly: </span>
            Industrial CO2 for extractions comes from byproducts – primarily hydrogen and ammonia manufacturing and fermentation for ethanol. CO2used for extractions does not contribute to the overall atmospheric CO2 levels.
          </p>
          <p className='superCritical-text superCritical-p'>
            <span className='superCritical-bold'>Subcritical: </span>
            With respect to plant oil extractions, subcritical CO2 has lower solvency power and thus tends to pull mostly lighter oils and leave behind most waxes, paraffin and resins. Subcritical CO2 is also relatively cold and therefore very effective in extracting temperature-sensitive volatile oils and terpenes. Subcritical CO2 is ideal for extracting and preserving light oils from the plant material.  Extraction times can be longer and yields are generally lower due to the fact that there will still be residual waxes and resins that are left behind in the plant material.
          </p>
          <p className='superCritical-text superCritical-p'>
            <span className='superCritical-bold'>Supercritical: </span>
            Supercritical. Supercritical CO2 can be a much stronger solvent than subcritical CO2.  In addition to extracting lighter oils, supercritical CO2 can extract the higher molecular weight materials (like waxes, paraffins, lipids and resins) from the plant, allowing for higher yields and a more complete extraction. The stronger solvency power also makes the extraction times faster.   Supercritical CO2 extractions at very high temperatures and pressures (higher than 120F and 5000psi) can cause thermal degradation of the oils, and when extracting green plant materials can have the potentially negative consequence of extracting chlorophyll.
          </p>
        </div>

      </div>
    </div>
  )
};

export default SuperCritical;