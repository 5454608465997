const total = (state = 0, { type, payload }) => {
  switch (type) {
    case "INCREASE_TOTAL":
      console.log("Payload: ", payload)
      const increasedTotal = state + payload
      return increasedTotal
    default:
      return state
  }
}

export default total;