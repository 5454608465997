import React, { Component } from "react";
import "./Products.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Product from "../Product/Product.js";
import { getProducts } from "../../store/actions/product";
import { addToCart } from "../../store/actions/shoppingCart";
import { increaseTotal } from "../../store/actions/total";

import MiniCart from "../MiniCart/MiniCart.js";

class Products extends Component {
  constructor() {
    super();
    this.state = {
      products: []
    };
    this.updateQuantity = this.updateQuantity.bind(this);
    this.increaseTotal = this.increaseTotal.bind(this);
  }

  static propTypes = {
    getProducts: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired
  };

  static defaultProps = {
    products: [],
    shoppingCart: []
  };

  increaseTotal(quantity) {
    console.log("quantity", quantity);
    this.props.increaseTotal(quantity);
  }

  updateQuantity(quantity, ProductName) {
    const updatedQuantityState = this.state.products;
    updatedQuantityState.map(product => {
      if (product.name === ProductName) {
        product.quantity = quantity;
        console.log(product);
        this.props.addToCart(product);
      }
      return product;
    });
    this.setState({
      products: updatedQuantityState
    });
  }

  setProductsToState(products) {
    const productsState = products.map(product => {
      delete product.created_at;
      delete product.updated_at;
      return product;
    });
    this.setState({
      products: productsState
    });
  }

  async componentWillMount() {
    const currentProducts = await this.props.getProducts();
    this.setProductsToState(currentProducts.payload);
  }

  componentDidMount() {}

  render() {
    const products = this.props.products.map(product => {
      return (
        <Product
          product={product}
          updateQuantity={this.updateQuantity}
          increaseTotal={this.increaseTotal}
          key={product.name}
        />
      );
    });

    return (
      <div>
        <MiniCart />
        Products
        <div className="products-container">{products}</div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  products: state.products,
  shoppingCart: state.shoppingCart,
  total: state.total
});

const dispatchToProps = dispatch => ({
  getProducts: () => dispatch(getProducts()),
  addToCart: product => dispatch(addToCart(product)),
  increaseTotal: quantity => dispatch(increaseTotal(quantity))
});

export default connect(mapStateToProps, dispatchToProps)(Products);
