import React from 'react';
import './Hero.css';
import { Link } from "react-router-dom";

const Hero = () => {
  return(
    <div className='hero-container'>

        <div className='hero-login-container'>
          {/* <Link className='hero-login-link' to='/login'>CUSTOMER LOGIN</Link> */}
        </div>
        <div className='hero'></div>
        <h3 className='header-tagline'>
          Oklahoma's Premier Cannabis Extractions Company
        </h3>
        <h4>Manufacturers Of</h4>
          <div className='brands-card'>
            <div className='hero-vibe-logo'></div>
            <p className='brands-card-text'>Edibles</p>
          </div>
          {/* <a
            className="hero-find-products-button"
            href="https://www.google.com/maps/search/the+green+solution+the+gree+dragon"
            target="_blank"
            rel="noopener noreferrer"
          >
            Find Our Products
                  </a> */}

    </div>
  )
};

export default Hero;