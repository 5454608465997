import React, { Component } from 'react';
import './TabBar.css';
import { Link } from "react-router-dom";


class TabBar extends Component {
  constructor() {
    super();
    this.state = {
      shouldShow: false
    }
  }
  
  handleToggle() {
    const toggleState = this.state
    toggleState.shouldShow = !toggleState.shouldShow
    this.setState({
      shouldShow: toggleState.shouldShow
    })
  }


  render() {
    const sideNav = this.state.shouldShow === true ? 
      <div className='side-nav'>
        <Link className='side-nav-link' to='/login' onClick={() => {
          this.handleToggle()
        }}>Log In</Link>
        <Link className='side-nav-link' to='/signup' onClick={() => {
          this.handleToggle()
        }}>Sign Up</Link>
        <Link className='side-nav-link' to='/' onClick={() => {
          this.handleToggle()
        }}>Home</Link>
        <Link className='side-nav-link' to='/user-account-page' onClick={() => {
          this.handleToggle()
        }}>Account Page</Link>
        <Link className='side-nav-link' to='/control-panel' onClick={() => {
          this.handleToggle()
        }}>Control Panel</Link>
      </div> 
      :
      <div className='side-nav hide'></div>
    
    
    

      return [
        <div className='TabBar'>
          <Link className='tab' to='/'>Home</Link>
          <Link className='tab' to='/products'>Shop</Link>
          <Link className='tab' to='/login'>Log In</Link>
          <div className='tab'
            onClick={() => {
              this.handleToggle()
            }}
          >More</div>
          { sideNav }
        </div>
    ]
  }
};

export default TabBar;