import React from 'react';
import './PrivateLabel.css';

const PrivateLabel = () => {

  return (
    <main className='private-label-offer'>
      <div className='green-break'></div>
      <div className='pl-offer-content'>
        <h3 className='pl-title'>PRIVATE LABEL PROCESSING</h3>
        <p className='pl-offer-text'>
          We offer private label processing at extremely competitive rates. With ELITE EXTRACTIONS, you can expect prompt communication, efficient turn-around times and most importantly, a quality product. 
          <br /><br />
          Products include cured resin, solventless extractions(fullmelt and rosin) and edible products.
          <br /><br />
          Bulk discounts available.  Call us for more details.
              </p>
        <h4>What to expect</h4>

        <p className='private-label-li'>
          <span className='begining-bold'>FREE CONSULTATION: </span>
          Before we begin processing for your facility, Raw materials provided to Elite must be tested and screened for any harmful materials. This screening should also include cannabinoid percentages.
        </p>
        <p className='private-label-li'>
          <span className='begining-bold'>10 DAY TURNAROUND: </span>
          We’ve heard stories of other extractors taking their sweet time with the run, letting the client’s supply sit around for months as it gradually loses quality. The best time to extract cannabis is when it’s at its peak freshness, to preserve the natural flavor and potency.
        </p>
        <p className='private-label-li'>
          <span className='begining-bold'>GORGEOUS PACKAGING:</span>
          Our cannabis oil cartridges  will not only look good on the shelf, they’ll also contain unique information about the concentrates inside.
        </p>
        <p className='private-label-li'>
          <span className='begining-bold'>3-STEP QC MEASURES: </span>
          Every run of  your product gets the same treatment our lab technicians give our own. We use three rigorous steps to quality control every run to produce only the cleanest, freshest cannabis extract.
        </p>
        {/* <p className='private-label-li'>
          <span className='begining-bold'>DETAILED EXTRACTION LOG: </span>
          We provide data rich extraction logs upon delivery and are always available for questions and feedback.
        </p> */}
        <a

          className="private-label-link"
          href="https://www.google.com/maps/search/the+green+solution+the+gree+dragon"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Us
            </a>
      </div>
      <div className='green-break'></div>
    </main>
  )
};

export default PrivateLabel;