import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer>

      <div className='footer-div'>
        <h4 className='footer-h4'>Where to Find Our Products</h4>
      </div>

      <div className='footer-div'>
        <h4 className='footer-h4'>Connect with Us</h4>
        <div className='social-media-container'>

          <a className="facebook-logo"
            alt="facebook-logo"
            href='https://www.facebook.com/EliteExtractions/'
          >
          </a>
          
          <a className="twitter-logo" 
             alt="twitter-logo"
            href='https://twitter.com/EExtractions'
          >
          </a>

          <a className="instagram-logo"
            alt="instagram-logo"
            href='www.instagram.com'
          >
          </a>

          <a className="youtube-logo"
            alt="twitter-logo"
            href='www.youtube.com'
          >
          </a>
        </div>
        <p className='footer-p'>Need Immediate Help?</p>
        <a
          className="footer-link"
          href='tel:1-405-761-2217'
        >Call</a>
        <a
          className="footer-link"
          href='mailto:buddy@elite-extractions.com'
        >
          Email</a>
      </div>

      <div className='footer-div'>
        <h4 className='footer-h4'>QuickLinks and Education</h4>
        <a
          className='footer-location-link'
          href="https://www.leafly.com/news/cannabis-101/the-complete-list-of-cannabis-delivery-methods"
          target="_blank"
          rel="noopener noreferrer"
        >Methods of Consumption</a>
        <a
          className='footer-location-link'
          href="https://www.webmd.com/a-to-z-guides/medical-marijuana-faq"
          target="_blank"
          rel="noopener noreferrer"
        >How Does Cannabis Work?</a>
        <a
          className='footer-location-link'
          href="https://lifehacker.com/the-beginners-guide-to-edibles-1821047006"
          target="_blank"
          rel="noopener noreferrer"
        >Know Your Edibles</a>
        <a
          className='footer-location-link'
          href="https://keytocannabis.com/blogs/cannabis/the-thc-dosage-guide-flower-edibles-concentrates-and-more"
          target="_blank"
          rel="noopener noreferrer"
        >Tolerance Guidlines</a>
        <a
          className='footer-location-link'
          href="https://www.leafly.com/news/cannabis-101/sativa-indica-and-hybrid-differences-between-cannabis-types"
          target="_blank"
          rel="noopener noreferrer"
        >Which Strain is Right for You?</a>
      </div>

    </footer>
  )
};

export default Footer;