export const getCustomers = () => dispatch => {
  return fetch('https://elite-extractions-api.herokuapp.com/api/customers')
    .then(res => res.json())
    .then(customers =>  {
      dispatch({ type: "GET_CUSTOMERS", payload: customers })
    }
      )
};

export const addCustomer = (customer) => dispatch => {
  return fetch('https://elite-extractions-api.herokuapp.com/api/customers', {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ customer })
  })
    .then(res => res.json())
    .then(newCustomer => {
        console.log('New Customer: ', newCustomer)
      dispatch({ type: "SIGNIN_USER", payload: newCustomer })
    })
};



