import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import './PastOrderDetails.css';
import { addToCart } from '../../store/actions/shoppingCart';
import { increaseTotal } from '../../store/actions/total';  


const PastOrderDetails = ({ pastOrder, addToCart, increaseTotal }) => {
  // const pastProducts = pastOrder.order.products.map(product => {
  //   delete 
  // })

  const setProducts = (array) => {
    array.forEach(product=> {
      console.log(product)
      addToCart(product)
      increaseTotal(product.price * product.quantity)
    })
  }
  const products = pastOrder.order.products.map((product) => {
    const productTotal = product.price * product.quantity;
    return (
      <tr>
        <td>
          {product.id}
        </td>
        <td>
          {product.name}
        </td>
        <td>
          {product.shortDescription}
        </td>
        <td>
          {(product.price * .01).toFixed(2)}
        </td>
        <td>
          {product.quantity}
        </td>
        <td>
          ${(productTotal * .01).toFixed(2)}
        </td>
      </tr>
    )
  })

  // <p className='past-order-total'>TOTAL: ${pastOrder.order.total}</p>
  return (
    <div className='past-order-details'>
      <p className='past-order-dispensary-name'>{pastOrder.dispensaryName}</p>
      <table className='past-order-container'>
        <tbody>
          <tr>
            <td>
              Product ID
            </td>
            <td>
              Product Name
            </td>
            <td>
              Product Description
            </td>
            <td>
              Product Price
            </td>
            <td>
              Product Quantity
            </td>
            <td>
              Total
            </td>
          </tr>
          {products}
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td> ${(pastOrder.order.total * .01).toFixed(2)} </td>
          </tr>
        </tbody>
      </table>
      <Link className='pod-reorder-button' 
            to='/shopping-cart'
            onClick={()=>{
              const product = {};
              product.fullDescription = pastOrder.order
              setProducts(pastOrder.order.products);
              // this.props.addToCart(product);
            }}
      >
        Reorder
      </Link>
    </div>
  )
};

const mapStateToProps = (state) => ({
  pastOrder: state.pastOrder
})

const dispatchToProps = (dispatch) => ({
  addToCart: (product) => dispatch(addToCart(product)),
  increaseTotal: (quantity) => dispatch(increaseTotal(quantity))
})

export default connect(mapStateToProps, dispatchToProps)(PastOrderDetails);