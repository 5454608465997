import React from 'react';
import { Link } from "react-router-dom";
import './ControlPanel.css';


const ControlPanel = () => {

  return(
    <div className='Control-Panel'>
      <h3 className='control-panel-title'>Control Panel</h3>
      <div>
        <Link className='control-panel-link' to='/orders'>View Orders</Link>
        
        <Link className='control-panel-link' to='/customers'>View Customers</Link>

        <Link className='control-panel-link' to='/admin-products'>View Products</Link>




      </div>
    </div>
  )

};

export default ControlPanel;