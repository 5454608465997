export const getOrders = () => dispatch => {
  return fetch('https://elite-extractions-api.herokuapp.com/api/orders')
    .then(res => res.json())
    .then(orders => {
      dispatch({ type: "GET_ORDERS", payload: orders })
    }
    )
};

export const getCustomerOrders = (id) => dispatch => {
  return fetch(`https://elite-extractions-api.herokuapp.com/api/orders/${id}`)
    .then(res => res.json())
    .then(orders => {
      console.log(orders)
      return orders
    })
}

export const addOrder = (order) => {


    return fetch('https://elite-extractions-api.herokuapp.com/api/orders', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ order })
    })
      .then(res => res.json())
      .then(newCustomer => {
        console.log(newCustomer)
      })

}