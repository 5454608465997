import React from 'react';
import { connect } from 'react-redux';
import './OrderDetails.css';


const OrderDetails = (props) => {
  const order = props.activeOrder;
  const orderDetails = props.activeOrder.order;
  console.log(order)

  const products = orderDetails.products.map((product) => {
    return(
      <tr>
        <td>
          {product.id}
        </td>
        <td>
          {product.name}
        </td>
        <td>
          ${(product.price * .01).toFixed(2)}
        </td>
        <td>
          {product.quantity}
        </td>
        <td>
          ${(product.price * product.quantity * .01).toFixed(2)}
        </td>
      </tr>
    )
  })

  return(
    <div className='order-details'>
      <div className='order-customer-info'>
        <h4>Order ID: {order.id} </h4>
        <h4>Dispensary Name: {order.dispensaryName}</h4>
        <h4>Contact Person: {order.userContactPerson}</h4>
        <h4>Phone Number: {order.phoneNumber}</h4>
        <h4>Email: {order.userEmail}</h4>
      </div>
      <table className='order-table'>
        <tbody>
          <tr>
            <td>
              Product ID
            </td>
            <td>
              Product Name
            </td>
            <td>
              Product Price
            </td>
            <td>
              Product Quantity
            </td>
            <td>
              Item Total
            </td>
          </tr>
         { products }
         <tr>
           <td></td>
            <td></td>
            <td></td>
            <td>
              Total:
            </td>
            <td>
              ${(props.activeOrder.order.total* .01).toFixed(2)}
            </td>

         </tr>
        </tbody>
      </table>
    </div>
  )
};

const mapStateToProps = (state) => ({
  activeOrder: state.activeOrder
})



export default connect(mapStateToProps, null)(OrderDetails);