import React from 'react';
import './ThankYouPage.css';

const ThankYouPage = () => {
  return(
    <div>
      <div className='thank-you-logo'></div>
      <div className='thank-you-page'>
        <h3 className='thank-you-title'>Thank you for ordering from Elite Extractions!</h3>
        <p classname='thank-you-p'>You should be recieving an email shortly reviewing your order.  If you do not recieve this email please call: 405-555-5555 or email Buddy@elite-extractions.com</p>
      </div>
    </div>
  )
};

export default ThankYouPage;