import React, { Component } from 'react';
import './UserAccountPage.css';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { getCustomerOrders } from '../../store/actions/Order.js';
import { addOrder } from '../../store/actions/Order.js';
import { setPastOrder } from '../../store/actions/pastOrder.js';


class UserAccountPage extends Component {

  constructor() {
    super();
    this.state = {
      userOrders: []
    }
  }

  setCustomerOrdersToState(orders) {

    this.setState({
      userOrders: orders
    })
  }

  async componentWillMount() {
    const userOrders = await this.props.getCustomerOrders(this.props.user.id);
    this.setCustomerOrdersToState(userOrders)
  }

  render() {

    const pastOrders = () => {
      if( this.state.userOrders.length > 0) {
        console.log('Boom!')
        const orders = this.state.userOrders.map(order => {
          const orderDate = order.created_at.substring(0, 10);
          return (
            <tr>
              <td>
                {orderDate}
              </td>
     
              <td>
                $ { order.order.total }
              </td>
              <td className='view-order-button account-order-button'>
                <Link className='reorder-link' 
                      to='/past-order-details'
                      onClick={
                        () => {
                          console.log(order)
                          this.props.setPastOrder(order)
                        }
                      }
                >
                  View
                </Link>
              </td>

              <td className='reorder-button account-order-button'>
                <Link className='reorder-link' 
                      to='/'
                      onClick={
                        () => {
                          console.log(order)
                          // this.props.addOrder(order)
                        }
                      }
                >
                  Reorder
                </Link>
              </td>
            </tr>
          )
        })
        return (
          orders
        )
      } else {
        return (
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        )
      }
    }

    return (
      <div className='user-account-page'>
        <h3 className='user-account-title'>User Account Page</h3>
        <Link className='user-account-edit-account-button' to='/'>Edit Account</Link>
        <table className='user-account-orders-table'>
          <tbody>
            <tr>
              <td>
                Date Ordered
              </td>
              <td>
                Total
              </td>
              <td>
                View Order
              </td>
              <td>
                Click to Reorder
              </td>
            </tr>

                { pastOrders() }
     
          </tbody>
        </table>

      </div>
    )
  }

};


const mapStateToProps = (state) => ({
  user: state.user
})

const dispatchToProps = (dispatch) => ({
  getCustomerOrders: (id) => dispatch(getCustomerOrders(id)),
  addOrder: (order) => dispatch(addOrder(order)),
  setPastOrder: (order) => dispatch(setPastOrder(order))
})

export default connect(mapStateToProps, dispatchToProps)(UserAccountPage);