import React, { Component } from "react";
import "./normalize.css";
import "./App.css";
import Customers from "./components/customers/Customers.js";
import { Route } from "react-router";
import { Provider } from "react-redux";
import store from "./store";
import SignupForm from "./components/SignUpForm/SignupForm.js";
import Products from "./components/Products/Products.js";
import Login from "./components/Login/Login.js";
import ShoppingCart from "./components/ShoppingCart/ShoppingCart.js";
import Hero from "./components/Hero/Hero.js";
import Footer from "./components/Footer/Footer";
import EmailSignup from "./components/EmailSignup/EmailSignup.js";
import PrivateLabel from "./components/PrivateLabel/PrivateLabel.js";
// import Slideshow from './components/SlideShow/SlideShow.js';
import TabBar from "./components/TabBar/TabBar.js";
import SuperCritical from "./components/SuperCritical/SuperCritical.js";
import Orders from "./components/Orders/Orders.js";
import ApexSystem from "./components/ApexSystem/ApexSystem.js";
import OrderDetails from "./components/OrderDetails/OrderDetails.js";
import ThankYouPage from "./components/ThankYouPage/ThankYouPage.js";
import ControlPanel from "./components/ControlPanel/ControlPanel.js";
import AdminProducts from "./components/AdminProducts/AdminProducts.js";
import UserAccountPage from "./components/UserAccountPage/UserAccountPage.js";
import PastOrderDetails from "./components/PastOrderDetails/PastOrderDetails.js";
import TemporaryMessage from "./components/TemporaryMessage/TemporaryMessage.js";
import WhoWeAre from "./components/WhoWeAre/WhoWeAre.js";
import Prices from "./components/Prices/Prices.js";
import EliteProducts from "./components/EliteProducts/EliteProducts.js";

class App extends Component {
  constructor() {
    super();
    this.state = {
      active: true
    };
  }

  toggleState() {
    this.setState({
      active: true
    });
  }

  render() {
    return (
      <Provider store={store}>
        <div className="App">
          {/* <Route path='/'
            render={() =>
              <>
                <TabBar />
              </>
            }
          /> */}

          <Route
            exact
            path="/past-order-details"
            render={() => <PastOrderDetails />}
          />

          <Route
            exact
            path="/user-account-page"
            render={() => (
              <>
                <UserAccountPage />
              </>
            )}
          />

          <Route
            exact
            path="/prices"
            render={() => (
              <>
                <Prices />
                {/* <Footer /> */}
              </>
            )}
          />

          <Route
            exact
            path="/"
            render={() => (
              <>
                <Hero />
                <WhoWeAre />
                <SuperCritical />

                {/* <EmailSignup /> */}

                {/* <ApexSystem /> */}
                <PrivateLabel />
                {/* <Slideshow /> */}
                <Footer />
              </>
            )}
          />

          <Route
            exact
            path="/login"
            render={() => (
              <>
                <Login />
              </>
            )}
          />

          <Route
            exact
            path="/products"
            render={() => (
              <>
                <Products />
              </>
            )}
          />

          <Route
            exact
            path="/elite-products"
            render={() => (
              <>
                <EliteProducts />
              </>
            )}
          />

          <Route
            exact
            path="/shopping-cart"
            render={() => (
              <>
                <ShoppingCart />
              </>
            )}
          />

          <Route
            exact
            path="/signup"
            render={() => (
              <>
                <SignupForm />
              </>
            )}
          />

          <Route
            exact
            path="/thankyou"
            render={() => (
              <>
                <ThankYouPage />
              </>
            )}
          />

          <Route
            exact
            path="/control-panel"
            render={() => (
              <>
                <ControlPanel />
              </>
            )}
          />

          <Route
            exact
            path="/admin-products"
            render={() => (
              <>
                <AdminProducts />
              </>
            )}
          />

          <Route
            exact
            path="/orders"
            render={() => (
              <>
                <Orders />
              </>
            )}
          />

          <Route
            exact
            path="/order-details"
            render={() => (
              <>
                <OrderDetails />
              </>
            )}
          />

          <Route
            exact
            path="/customers"
            render={() => (
              <>
                <Customers />
              </>
            )}
          />
        </div>
      </Provider>
    );
  }
}

export default App;
