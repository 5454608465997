import React, { Component } from 'react';
import './Product.css';

class Product extends Component {
  constructor() {
    super();
    this.state = {
      quantity: 1,
      isFavorite: false
    }
  }

  handleIncrease() {
    const increasedQuantity = this.state.quantity + 1;
    this.setState({
      quantity: increasedQuantity
    })
  }

  handleDecrease() {
    if (this.state.quantity > 0) {
      const decreasedQuantity = this.state.quantity - 1;
      this.setState({
        quantity: decreasedQuantity
      })
    } 
  }

  handleChange(value) {
    this.setState({
      quantity: value
    });
  }

  toggleFavorite() {
    const updateFavorite = !this.state.isFavorite
    
    this.setState({
      isFavorite: updateFavorite
    }, ()=>{
        console.log('FAV: ', updateFavorite)
    })
  }

  render() {
    let displayPrice = (this.props.product.price) * .01;
    displayPrice = '$' + displayPrice.toFixed(2);
    let imageClassName = `product-image product-image-${this.props.product.id}`;
    let favIconClass = ()=>{
          let favClass = ''
        if (this.state.isFavorite === true) {
          console.log('TRUE')
          favClass = 'product-favorite-icon product-favorited'
        } else {
          console.log('false')
          favClass = 'product-favorite-icon'
        }
        
        return favClass
      // this.isFavorite === true ? 'product-favorite-icon' : 'product-favorite-icon product-favorited';
      // console.log(FavClass)
      
    }


    return(
      <div className='product'>
        <div className={imageClassName}>
          <div class={favIconClass()}
               onClick={()=>{
                 console.log('click!')
                 this.toggleFavorite();
               }}          
          ></div>
        </div>
        <p className='product-title'>{this.props.product.name}</p>
        <p>{this.props.product.shortDescription}</p>
        <p className='product-price'>{displayPrice}</p>
        <div className='product-button details-button'>
          <p className='product-button-text'>Details</p>
        </div>
        <div className='add-to-cart-container'>
            <div className='amount-container'>
              <div className='quantity-display-reduce'
                    onClick={() => {
                      this.handleDecrease();
                    }

                    }
              >-</div>
              <div className='quantity-display'>{this.state.quantity}</div>
              <div className='quantity-display-increase'
                   onClick={() => {
                     this.handleIncrease();
                   }}
              >+</div>
            </div>
          <div className='product-button add-to-cart-button'
                onClick={()=> {
                  this.props.updateQuantity(this.state.quantity, this.props.product.name);
                  console.log('Quantity: ', this.state.quantity)
                  console.log("Price: ", this.props.product.price)
                  this.props.increaseTotal(this.state.quantity * this.props.product.price );
                }
              }
                >
            <p className='product-button-text'>Add to Cart</p>
          </div>
        </div>
       
      </div>
    )
  }
};

export default Product;
