import React from "react";
import "./EliteProducts.css";

const EliteProducts = () => {
  return (
    <div className="Elite-Products">
      <div className="prices-logo"></div>
      <p className="prices-intro">
        Please see below the products that are available . You can email or call
        us direct with any inquires at{" "}
        <a className="prices-email" href="mailto:buddy@elite-extractions.com">
          Buddy@Elite-Extractions.com
        </a>{" "}
        or{" "}
        <a className="prices-phone" href="tel:1-405-761-2217">
          405-761-2217
        </a>{" "}
        if interested.
      </p>

      <h2>Edibles</h2>
      <p className="prices-note">
        *Our Edible products do not smell or taste like Cannabis.
      </p>
      <div className="elite-products-container">
        <div className="elite-product">
          <div className="ep-product-image elite-product-chocolate"></div>
          <p className="elite-product-name elite-p">Chocolates</p>
          <p className="elite-product-description elite-p">
            25mg individually wrapped per package, quantity per box 4.
          </p>
          <p className="elite-product-price elite-p">Price: $8.00</p>
        </div>
        <div className="elite-product">
          <div className="ep-product-image elite-product-caramel"></div>
          <p className="elite-product-name elite-p">Caramels</p>
          <p className="elite-product-description elite-p">
            25mg individually wrapped per package, quantity per box 4.
          </p>
          <p className="elite-product-price elite-p">Price: $8.00</p>
        </div>
        <div className="elite-product">
          <div className="ep-product-image elite-product-rice-krispie"></div>
          <p className="elite-product-name elite-p">Krispie Treats</p>
          <p className="elite-product-description elite-p">
            25mg individually wrapped per package, quantity per box 4.
          </p>
          <p className="elite-product-price elite-p">Price: $8.00</p>
        </div>
      </div>
      <h2>THC Capsules</h2>
      <div className="elite-products-container">
        <div className="elite-product">
          <div className="ep-product-image elite-product-capsules"></div>
          <p className="elite-product-name elite-p">10mg Capsules</p>
          <p className="elite-product-description elite-p">
            Pure Distillate, 10 per bottle
          </p>
          <p className="elite-product-price elite-p">Price: $8.50</p>
        </div>

        <div className="elite-product">
          <div className="ep-product-image elite-product-capsules"></div>
          <p className="elite-product-name elite-p">25mg Capsules</p>
          <p className="elite-product-description elite-p">
            Pure Distillate, 10 per bottle
          </p>
          <p className="elite-product-price elite-p">Price: $14.00</p>
        </div>

        <div className="elite-product">
          <div className="ep-product-image elite-product-capsules"></div>
          <p className="elite-product-name elite-p">50mg Capsules</p>
          <p className="elite-product-description elite-p">
            Pure Distillate, 5 per bottle
          </p>
          <p className="elite-product-price elite-p">Price: $14.00</p>
        </div>
      </div>
      <h2>Carts and Distillate</h2>
      <div className="elite-products-container">
        <div className="elite-product">
          <div className="ep-product-image elite-product-cart"></div>
          <p className="elite-product-name elite-p">Cart .5ml</p>
          <p className="elite-product-description elite-p">
            65% THC Bubble Kush
          </p>
          <p className="elite-product-price elite-p">Price: $14.00</p>
        </div>

        <div className="elite-product">
          <div className="ep-product-image elite-product-cart"></div>
          <p className="elite-product-name elite-p">Disposables Cart .5ml</p>
          <p className="elite-product-description elite-p">
            65% THC Strawberry AKA
          </p>
          <p className="elite-product-price elite-p">Price: $15.00</p>
        </div>

        <div className="elite-product">
          <div className="ep-product-image elite-product-cart"></div>
          <p className="elite-product-name elite-p">
            Non flavored natural Distillate
          </p>
          <p className="elite-product-description elite-p">.5 ml 85% THC</p>
          <p className="elite-product-price elite-p">Price: $15.00</p>
        </div>
      </div>
    </div>
  );
};

export default EliteProducts;
