
const activeOrderReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case "SET_ACTIVE_ORDER":
      return payload
    default:
      return state
  }
}

export default activeOrderReducer;