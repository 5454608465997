import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addCustomer } from '../../store/actions/customer';
import './SignupForm.css';
import { Link } from "react-router-dom";

class SignupForm extends Component {
  constructor() {
    super();
    this.state = {
      dispensaryName: '',
      contactName: '',
      password: '',
      phoneNumber: '',
      emailAddress: '',
      deliveryAddress: '',
      mmjNumber: '',
      disabled: true
    };
  }

  handleChange(value, name) {
    this.setState({
      [name]: value
    });
    this.checkButtonDisable();
  }

  checkButtonDisable() {
    if (this.state.dispensaryName.length && this.state.contactName.length && this.state.password.length && this.state.phoneNumber.length && this.state.emailAddress.length && this.state.deliveryAddress.length && this.state.mmjNumber.length ) {
      console.log('Boom!')
      this.setState({disabled: false})
    }
    else {
      this.setState({disabled: true})
    }
  }

  verifyInput(event) {
    if (this.state.dispensaryName.length > 2 && this.state.contactName.length > 2 && this.state.password.length > 2 && this.state.phoneNumber.length > 2 && this.state.emailAddress.length > 2 && this.state.deliveryAddress.length > 2 && this.state.mmjNumber.length > 2 ) {
      this.submitInfo(event)
    } else {
      alert('Missing Required Information!')
    }

  }

  submitInfo(e) {
    const request = this.state;

    const newCustomer = {
      name: request.dispensaryName,
      contactPerson: request.contactName,
      password: request.password,
      phoneNumber: request.phoneNumber,
      emailAddress: request.emailAddress,
      deliveryAddress: request.deliveryAddress,
      mmjNumber: request.mmjNumber
    }
    
    this.props.addCustomer(newCustomer);
  }

  render() {
    let submitButton = <button to='/products'
      className='signup-page-button'
      disabled
      key='10003'
    >Submit</button>

    if (this.state.disabled === false) {
      submitButton = <Link to='/products'
        className='signup-page-button'
        key='10002'
        onClick={(event) => {
          this.verifyInput(event)
        }
        }
      >Submit</Link>
    }

    return (

      <div className='signup-container'>
        <div className='signup-logo'></div>
        <form className='signup-form' key='10000'>
          <h3 className='signup-title'>
            Create your account
          </h3>
          <div className='signup-form-input-container'>
            <label for='dispensaryName' className='signup-label'>Dispensary Name</label>
            <input name='dispensaryName' className='signup-input' type='text' key='1' placeholder='' onChange={(event) => {
              this.handleChange(event.target.value, event.target.name)
            }}></input>
          </div>
          <div className='signup-form-input-container'>
            <label for='contactName' className='signup-label'>Contact Person</label>
            <input name='contactName' className='signup-input' type='text' key='2' placeholder='' onChange={(event) => {
              this.handleChange(event.target.value, event.target.name)
            }}></input>
          </div>
          <div className='signup-form-input-container'>
            <label for='password' className='signup-label'>Password</label>
            <input name='password' className='signup-input' type='text' key='3' placeholder='' onChange={(event) => {
              this.handleChange(event.target.value, event.target.name)
            }}></input>
          </div>
          <div className='signup-form-input-container'>
            <label for='phoneNumber' className='signup-label'>Phone Number</label>
            <input name='phoneNumber' className='signup-input' type='text' key='4' placeholder='' onChange={(event) => {
              this.handleChange(event.target.value, event.target.name)
            }}></input>
          </div>
          <div className='signup-form-input-container'>
            <label for='emailAddress' className='signup-label'>Email Address</label>
            <input name='emailAddress' className='signup-input' type='email' key='5' placeholder='' onChange={(event) => {
              this.handleChange(event.target.value, event.target.name)
            }}></input>
          </div>
          <div className='signup-form-input-container'>
            <label for='deliveryAddress' className='signup-label'>Delivery Address</label>
            <input name='deliveryAddress' className='signup-input' type='text' key='6' placeholder='' onChange={(event) => {
              this.handleChange(event.target.value, event.target.name)
            }}></input>
          </div>
          <div className='signup-form-input-container'>
            <label for='city' className='signup-label'>City</label>
            <input name='city' className='signup-input' type='text' key='7' placeholder='' onChange={(event) => {
              this.handleChange(event.target.value, event.target.name)
            }}></input>
          </div>
          <div className='signup-form-input-container'>
            <label for='mmjNumber' className='signup-label'>State MMJ Number</label>
            <input name='mmjNumber' className='signup-input' type='text' key='8' placeholder='' onChange={(event) => {
              this.handleChange(event.target.value, event.target.name)
            }}></input>
          </div>
          {submitButton}
        </form>
      
      </div>

    )
  }

};

const mapStateToProps = (state) => ({
  user: state.user
})

const dispatchToProps = (dispatch) => ({
  addCustomer: (customer) => dispatch(addCustomer(customer))
})

export default connect(mapStateToProps, dispatchToProps)(SignupForm);