import React from "react";
import "./Prices.css";
import { Link } from "react-router-dom";

const Prices = () => {
  return (
    <div className="Prices">
      <div className="prices-logo"></div>
      <p className="prices-intro">
        Please see below the products that are available . You can email or call
        us direct with any inquires at{" "}
        <a className="prices-email" href="mailto:buddy@elite-extractions.com">
          Buddy@Elite-Extractions.com
        </a>{" "}
        or{" "}
        <a className="prices-phone" href="tel:1-405-761-2217">
          405-761-2217
        </a>{" "}
        if interested.
      </p>
      <table>
        <th>EDIBLES</th>

        <tr>
          <td>Chocolates, 4 - 25 mg individually wrapped per package.</td>
          <td>$8.00</td>
        </tr>
        <tr>
          <td>Caramels, 4 - 25 mg individually wrapped per package.</td>
          <td>$8.00</td>
        </tr>
        <tr>
          <td>Rice Krispies 4 - 25 mg individually wrapped per package.</td>
          <td>$8.00</td>
        </tr>
      </table>
      <p className="prices-note">
        *Our Edible products do not smell or taste like Cannabis.
      </p>
      <table>
        <th>THC CAPSULES</th>

        <tr>
          <td>10 mg capsules pure distillate, 10 per bottle.</td>
          <td>$8.50</td>
        </tr>
        <tr>
          <td>25 mg capsules pure distillate, 10 per bottle.</td>
          <td>$14.00</td>
        </tr>
        <tr>
          <td>50 mg capsules pure distillate, 5 per bottle.</td>
          <td>$14.00</td>
        </tr>
      </table>
      <table className="last-table">
        <th>CARTS AND DISPOSIBLES</th>
        <tr>
          <td>Cart .5ml 65% THC Bubble Kush </td>
          <td>$14.00</td>
        </tr>
        <tr>
          <td>Disposables .5ml 65% THC Strawberry AKA</td>
          <td>$15.00</td>
        </tr>
        <tr>
          <td>Non flavored natural Distillate .5 ml 85% THC</td>
          <td>$15.00</td>
        </tr>
      </table>
      <Link to="/elite-products" className="testey">
        TEST
      </Link>
      {/* Don't forget to remove this! */}
    </div>
  );
};

export default Prices;
