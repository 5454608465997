import React, { Component } from 'react';
import PropTypes from 'prop-types'
import {connect } from 'react-redux';
import {getCustomers} from '../../store/actions/customer';
import './customers.css';

class Customers extends Component {

  static propTypes = {
    getCustomers: PropTypes.func.isRequired,
    customers: PropTypes.array.isRequired
  }

  static defaultProps = {
    customers: []
  }

  componentWillMount() {
    this.props.getCustomers();
  }

  render() {
    return (
      <div>
        <h2 className='customers-title'>Customers</h2>
        <table className='customers'>
          <tbody>     
            <tr>
              <td>
                Customer ID
              </td>
              <td>
                Dispensary Name
              </td>
              <td>
                Contact Person
              </td>
              <td>
                Phone Number
              </td>
              <td>
                Email Address
              </td>
              <td>
                Delivery Street Address
              </td>
              <td>
                STATE LIC #
              </td>
            </tr>
  
          {this.props.customers.map(customer =>
            <tr key={customer.id}>
              <td>
                {customer.id}
              </td>
              <td>
                {customer.name} 
              </td>
              <td>
                {customer.contactPerson}
              </td>
              <td>
                {customer.phoneNumber}
              </td>
              <td>
                {customer.emailAddress}
              </td>
              <td>
                {customer.deliveryAddress}
              </td>
              <td>
                {customer.mmjNumber}
              </td>
              </tr>
            )}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customers: state.customers
})

const dispatchToProps = (dispatch) => ({
   getCustomers: () => dispatch(getCustomers())
})

export default connect(mapStateToProps, dispatchToProps)(Customers);
