import React from "react";
import "./WhoWeAre.css";
import { Link } from "react-router-dom";

const WhoWeAre = () => {
  return (
    <div className="who-we-are">
      <h3 className="about-title">Who We Are</h3>
      <p className="about-text">
        We are locally owned and operated. Elite Extractions was started in
        January 2019 by Buddy Moore, an Air Force veteran from the OKC area.
        Buddy has a passion for the business mostly because of what Cannabis has
        done to improve his life.
      </p>
      <p className="about-text">
        In 2013 Buddy started having back pain due to a bulging disk in his
        lower back. He tried everything from Chiropractics to Spinal injections.
        Then the disk ruptured and a piece of the disk lodged between his spine
        and a nerve root, damaging the nerve.
      </p>
      <p className="about-text">
        In 2014 he underwent two surgeries. The second did the trick but he was
        left with the damaged nerve that causes daily pain. The Medical Doctors
        had him on four different pharmaceuticals, all four highly addictive.
        Then he turned to Cannabis and in two weeks stoped taking all of the
        pharmaceutical drugs. Now he spends his days crafting Distillates and
        Edibles to help people like himself that suffer with pain.
      </p>
      <p>Please take some time to review our products and prices.</p>
      <Link to="/elite-products" className="prices-button">
        PRODUCTS
      </Link>
    </div>
  );
};

export default WhoWeAre;
