const shoppingCartReducer = (state = [], { type, payload }) => {
  switch (type) {
    case "ADD_TO_CART":
      const updatedCart = [...state, payload]
      return updatedCart
    default:
      return state
  }
}

export default shoppingCartReducer;