import React, { Component } from 'react';
import './MiniCart.css';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";



class MiniCart extends Component {

  constructor() {
    super();
    this.state = {
      total: 0
    }
  }

  componentDidMount() {
    // this.setState({ products: this.props.shoppingCart })
    // // const total = this.props.reduce((item, accu) => {
    // //       return accu + item.productTotal
    // // }, 0)
    // // console.log(total)
    // if (this.props.shoppingCart.length) {
    //   const totals = this.props.shoppingCart.map(item => {
    //     return item.price * item.quantity
    //   })
    //   const reducer = (accumulator, currentValue) => accumulator + currentValue;
    //   this.setState({
    //     total: totals.reduce(reducer)
    //   })
    // }
    console.log('PropsTotal: ', this.props.total)
    this.setState({
      total: this.props.total
    })
  }

  componentWillUpdate(nextProps) {
    // console.log("PrevProps: ", prevProps)
    // Typical usage (don't forget to compare props):
    if (this.state.total !== nextProps.total) {
      this.setState({
        total: nextProps.total
      })
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.state.total !== nextProps.total) {
  //     this.setState({
  //       total: this.nextProps.total
  //     })
  //   }
  // }

  render() {
    const cartItems = this.props.shoppingCart.length > 0 ? this.props.shoppingCart.length : 0;
    console.log(cartItems)
    return (
      // <div className='mini-cart-container'>
        <div className='mini-cart'>
          <p className='miniCart-display-text'>
            You have {cartItems} products in your cart. <br/>
            Cart Total: ${(this.state.total * .01).toFixed(2)}
          </p>

          <Link
            // onClick={(event) => {
            //   this.submitInfo(event);
            // }
            // }
            to='/shopping-cart'
            className='miniCart-button'
            key='2'
          > Check Out
          </Link>
      </div>
      // </div>
    )
  }
};

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCart,
  user: state.user,
  total: state.total
})

// const dispatchToProps = (dispatch) => ({
//   addOrder: (order) => dispatch(addOrder(order))
// })

export default connect(mapStateToProps, null)(MiniCart);