import React, { Component } from 'react';
import { addProduct } from '../../store/actions/product';
import { connect } from 'react-redux';


import './AddProduct.css';

class AddProduct extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      shortDescription: '',
      fullDescription: '',
      price: 0
    }
  }

  handleChange(value, name) {
    console.log(name, ': ', value)
    this.setState({
      [name]: value
    });
  }

  submitInfo(e) {
    const request = this.state;

    const newProduct = {
      name: request.name,
      shortDescription: request.shortDescription,
      fullDescription: request.fullDescription,
      price: request.price
    }
    console.log('NEW PRODUCT: ', newProduct)
    this.props.addProduct(newProduct);
  }

  render() {
    return(
      <form className='add-product-form'>
      <h3>Add A Product</h3>
      <div className='add-product-input-container'>

        {/* <label className='ap-input-label' for='product-name' >Product Name</label> */}
          <input className='ap-input' onChange={(event) => {
            this.handleChange(event.target.value, event.target.name)
          }} name='name' type='text' placeholder='product name'></input>
      </div>
      <div className='add-product-input-container'>
        {/* <label className='ap-input-label'for='product-short-description' >Product Short Description</label> */}
          <input className='ap-input' onChange={(event) => {
            this.handleChange(event.target.value, event.target.name)
          }} name='shortDescription' type='text' placeholder='product short description'></input>
      </div>
      <div className='add-product-input-container'>
        {/* <label className='ap-input-label'for='product-full-description' >Product Full Description</label> */}
          <input className='ap-input' onChange={(event) => {
            this.handleChange(event.target.value, event.target.name)
          }} name='fullDescription' type='text-field' placeholder='product Full description'></input>
      </div>
      <div className='add-product-input-container'>
        {/* <label className='ap-input-label' for='product-price' >Product Price</label> */}
        <input 
            className='ap-input' onChange={(event) => {
              this.handleChange(event.target.value, event.target.name)
            }} name='price' type='number' placeholder='Product Price example 2999'></input>
      </div>
        <div className='ap-submit-button' onClick={(event) => {
          this.submitInfo(event)
        }}>Submit</div>
      </form>
    )
  }

};

const mapStateToProps = (state) => ({
  user: state.user
})

const dispatchToProps = (dispatch) => ({
  addProduct: (product) => dispatch(addProduct(product))
})

export default connect(mapStateToProps, dispatchToProps)(AddProduct);