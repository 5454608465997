import React, { Component } from 'react';
import { loginUser } from '../../store/actions/user';
import { connect } from 'react-redux';
import './Login.css';
import { Link } from "react-router-dom";


class Login extends Component {

  

  constructor() {
    super();
    this.state = {
      emailAddress: '',
      password: '',
      disabled: true
    };
  }

  handleChange(value, name) {
    this.setState({
      [name]: value
    }, this.checkButtonDisable());
  }

  checkButtonDisable() {
    if (this.state.emailAddress.length && this.state.password) {
      console.log('Boom!')
      this.setState({ disabled: false })
    }
    else {
      this.setState({ disabled: true })
    }
  }

  submitInfo (e)  {
    const request = this.state;

    const requestedLogin = {
      emailAddress: request.emailAddress,
      password: request.password
    }
    this.props.loginUser(requestedLogin);
  }

  render() {
    let submitButton = <button to='/products'
      className='login-page-button'
      disabled
      key='10003'
    >Submit</button>

    if (this.state.disabled === false) {
      submitButton = <Link
        onClick={(event) => {
          this.submitInfo(event);
        }
        }
        to='/products'
        className='submit-btn login-page-button'
        key='2'
      >
        Log In
          </Link>
    }
    return(
      <div className='login-container'>
        <div className='login-logo'></div>
        <div className='login'>
          <h3 className='login-title'>
            Sign in to your account
          </h3>
          <div className='login-input-container'>
            <label 
              for='emailAddress'
              className='login-label'
            >
              Email Address (required)
            </label>
            <input 
              name='emailAddress' 
              className='login-input'
              type='text' 
              placeholder='' 
              onChange={(event) => {
              this.handleChange(event.target.value, event.target.name)
              }}>
            </input>
          </div>
          <div className='login-input-container'>
            <label 
              for='password'
              className='login-label'
            >
              Password (required)
            </label>
            <input
              name='password'
              className='login-input'
              type='text'
              placeholder=''
              onChange={(event) => {
                this.handleChange(event.target.value, event.target.name)
              }}>
            </input>
          </div>
          {submitButton}
          <p className='login-signup-text'>Don't have an account?</p>
          <Link
            onClick={(event) => {
              this.submitInfo(event);
            }
            }
            to='/signup'
            className='login-page-button new-account-button'
            key='2'
          >Create a New Account</Link>
        </div>
      </div>
    )
  }
};

const mapStateToProps = (state) => ({
  user: state.user
});

const dispatchToProps = (dispatch) => ({
  loginUser: (requestedLogin) => dispatch(loginUser(requestedLogin))
});

export default connect(mapStateToProps, dispatchToProps)(Login);

// const mapStateToProps = (state) => ({
//   customers: state.customers
// })

// const dispatchToProps = (dispatch) => ({
//   getCustomers: () => dispatch(getCustomers())
// })

// export default connect(mapStateToProps, dispatchToProps)(Customers);