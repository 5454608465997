import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ShoppingCart.css';
import { addOrder } from '../../store/actions/Order';
import { Link } from "react-router-dom";



class ShoppingCart extends Component {

  constructor() {
    super();
    this.state =  {
      products: [],
      total: 0
    }
  }

  increaseQuantity(id) {
    const updatedState = this.state.products.map((product) => {
      if (product.id === id) {
        product.quantity = product.quantity + 1;
        const updatedTotal = this.state.total + (product.price * 1)
        this.setState({
          total: updatedTotal
        })
      }
      return product;
    })
    this.setState({products: updatedState})
  }

  decreaseQuantity(id) {
    const updatedState = this.state.products.map((product) => {
      if (product.id === id && product.quantity > 0 ) {
        product.quantity = product.quantity - 1;
        const updatedTotal = this.state.total - (product.price * 1)
        this.setState({
          total: updatedTotal
        })
      }
      return product;
    })
    this.setState({ products: updatedState })
  }

  handleOrder(user, cart) {
    const newOrder = {
      userId: user.id,
      userContactPerson: user.contactPerson,
      userDeliveryAddress: user.deliverAddress,
      userEmail: user.emailAddress,
      userMmjNumber: user.mmjNumber,
      dispensaryName: user.name,
      phoneNumber: user.phoneNumber,
      order: {
        total: this.state.total,
        products: cart
      }
    }
    this.props.addOrder(newOrder)
  }

  componentWillMount() {
    this.setState({ products: this.props.shoppingCart })
    const totals = this.props.shoppingCart.map(item => {
      return item.price * item.quantity
    })
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    this.setState({
      total: totals.reduce(reducer)
    })
  }

  render() {

  const cartItems = this.state.products.map((item)=>{
      const productTotal = item.price * item.quantity;
      let displayPrice = item.price * .01
      displayPrice = '$' + displayPrice.toFixed(2)
      let displayTotal = productTotal * .01
      displayTotal = displayTotal.toFixed(2)
    return(
        <tr>
            <td>{item.name}</td>
            <td>{item.shortDescription}</td>
            <td>{displayPrice}</td>
            <td className='sc-quantity-container'>
              <div className='sc-quantity-display-reduce'
                  onClick={() => {
                    this.decreaseQuantity(item.id)
                  }
            }
              >
                -
              </div>
              <div className='sc-quantity-display'>
                {item.quantity}
              </div>
              <div className='sc-quantity-display-increase'
                   onClick={()=>{
                     this.increaseQuantity(item.id)
                    }
                   }
              >
                +
              </div>
            </td>
            <td>${displayTotal}</td>
         </tr>
    )
  })

    const displayOrdertotal = (this.state.total * .01).toFixed(2)

  // console.log('USER: ', user)
  // console.log('SHOPPING CART: ', shoppingCart)
  return(
    <div>
      <table className='shopping-cart'>
        <tbody>
          <tr>
            <td>Product</td>
            <td>Description</td>
            <td>Price</td>
            <td>Quantity</td>
            <td>Product Total</td>
          </tr>
          { cartItems }
          <tr>
            <td className='blank'></td>
            <td className='blank'></td>
            <td className='blank'></td>
            <td className='blank'>Total</td>
            <td>${displayOrdertotal}</td>
          </tr>
        </tbody>
      </table>

      <Link to='/thankyou' className='place-order-button'
           onClick={() => {
             this.handleOrder(this.props.user, this.state.products)
           }}
      
      >
        Place Order
      </Link>
    </div>
  )
  }
};

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCart,
  user: state.user
})

const dispatchToProps = (dispatch) => ({
  addOrder: (order) => dispatch(addOrder(order))
})

export default connect(mapStateToProps, dispatchToProps)(ShoppingCart);