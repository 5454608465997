export const getProducts = () => dispatch => {
  return fetch('https://elite-extractions-api.herokuapp.com/api/products')
    .then(res => res.json())
    .then(products => dispatch({ type: "GET_PRODUCTS", payload: products }))
};

export const addProduct = (product) => dispatch => {
  return fetch('https://elite-extractions-api.herokuapp.com/api/products', {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ product })
  })
    .then(res => res.json())
    .then(newProduct => {
      console.log(console.log(newProduct))
    })
};