export const loginUser = (requestedLogin) => dispatch => {
  return fetch(`https://elite-extractions-api.herokuapp.com/api/customers/${requestedLogin.emailAddress}`, {
    method: 'get',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  })
    .then(res => res.json())
    .then(signedinUser => {
      dispatch({ type: "SIGNIN_USER", payload: signedinUser })
    })
};