import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import './Orders.css';


import { getOrders } from '../../store/actions/Order.js';
import { setActiveOrder } from '../../store/actions/activeOrder.js';





class Orders extends Component {
  constructor() {
    super();
    this.state = {
      orders: []
    };
  }

  static defaultProps = {
    orders: []
  }

  setOrdersToState(orders) {
    const ordersState = orders.map(order => {
      delete order.created_at
      delete order.updated_at
      return order
    })
    this.setState({
      orders: ordersState
    })
  }

  async componentWillMount() {
    const currentOrders = await this.props.getOrders();
    this.setOrdersToState(currentOrders.payload);
  }

  render() {

    const orders = this.props.orders.map(order => {
      console.log("ORDER: ", order)
      return (
        <tr>
          <td>
            {order.id}
          </td>
          <td>
            {order.dispensaryName}
          </td>
          <td>
            ${(order.order.total * .01).toFixed(2)}
          </td>
          <td className='details-button-container'>
            <Link to='/order-details'
                className='order-details-button'
                onClick={()=> {
                  console.log('Boom!')
                  this.props.setActiveOrder(order);
                }}
            >
              Details
            </Link>
          </td>
        </tr>
        )
    })

    return (
      <div>
        <table className='orders-table'>
          <tbody>
            <tr>
              <td>
                Order Id
              </td>
              <td>
                Dispensary Name
              </td>
              <td>
                Order Total
              </td>
              <td>
                Order Info
              </td>
            </tr>
            { orders }
          </tbody>
        </table>

      </div>
    )
  }

};

const mapStateToProps = (state) => ({
  orders: state.orders
})

const dispatchToProps = (dispatch) => ({
  getOrders: () => dispatch(getOrders()),
  setActiveOrder: (order) => dispatch(setActiveOrder(order))
})

export default connect(mapStateToProps, dispatchToProps)(Orders);
