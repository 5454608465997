import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import createHistory from "history/createBrowserHistory";
import { Router } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop.js";

const history = createHistory();

ReactDOM.render(
  <Router history={history}>
    <ScrollToTop />
    <App />
  </Router>,
  document.getElementById("root")
);
